import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '@wallyhealth/web-core'
import ActivationStartPage from './ActivationStartPage'
import ActivationFamilyPage from './ActivationFamilyPage'
import { useOnboardGroup, useOrganizationInfo } from './hooks'
import ActivationConfirmationPage from './ActivationConfirmationPage'
import { CopyVariation } from 'pages/WelcomePage'
import ChatWidget from 'components/ChatWidget'
import config from 'config'

const StepId = {
  START: 1,
  CONFIRMATION: 2,
  FAMILY: 3
}

const JoinFlow = () => {
  const navigate = useNavigate()
  const { slug } = useParams()
  const { orgInfo, isLoadingOrgInfo } = useOrganizationInfo({ slug })
  const { onboardGroupRunner, onboardMembersRunner } = useOnboardGroup()
  const [stepId, setStepId] = useState(StepId.START)

  const scrollToTop = () => (
    window.scrollTo({ top: 0, behavior: 'smooth' })
  )
  useEffect(() => {
    scrollToTop()
  }, [stepId])

  const onHolderInfoCaptured = async (holder) => {
    await onboardGroupRunner.run({
      locationId: config.BIZ_LOCATION_ID,
      geoId: 1,
      organizationId: orgInfo.organizationId,
      holder,
      members: []
    })
    setStepId(StepId.CONFIRMATION)
  }

  const skipAddingMembers = () => {
    navigate('/welcome')
  }

  const onboardMembers = async (members) => {
    const { groupId, enrollmentToken } = onboardGroupRunner.data
    await onboardMembersRunner.run({
      groupId,
      members,
      enrollmentToken,
      geoId: 1,
      locationId: config.BIZ_LOCATION_ID
    })
    navigate(`/welcome?var=${CopyVariation.FAMILY}`)
  }

  if (isLoadingOrgInfo) {
    return <LoadingPage />
  }

  if (!isLoadingOrgInfo && !orgInfo) {
    navigate(config.HOMEPAGE_URL)
  }

  return (
    <div>
      {stepId === StepId.START &&
        <ActivationStartPage
          isActivating={onboardGroupRunner.isRunning}
          onInfoCaptured={onHolderInfoCaptured}
        />
      }
      {stepId === StepId.CONFIRMATION &&
        <ActivationConfirmationPage
          onSkipMembers={skipAddingMembers}
          onAddMembers={() => setStepId(StepId.FAMILY)}
        />
      }
      {stepId === StepId.FAMILY &&
        <ActivationFamilyPage
          onSkip={() => navigate('/welcome')}
          onInfoCaptured={onboardMembers}
          organizationName={orgInfo.organizationName}
          discountedCostInCents={orgInfo.discountedCostInCents}
          fullCostInCents={orgInfo.fullCostInCents}
          isOnboarding={onboardMembersRunner.isRunning}
        />
      }
      <ChatWidget />
    </div>
  )
}

export default JoinFlow
