import React, { useEffect } from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'
import { useLocation } from 'react-router-dom'
import intelService from '../../../services/intelService'

const PAGE_NAME = 'ScheduledPage'

const SCHEDULED_APPOINTMENT_SUBTITLE = 'Your appointment has been scheduled.'

const RENEWAL_APPOINTMENT_SUBTITLE = `${SCHEDULED_APPOINTMENT_SUBTITLE} \n\n\n Heads up! Your visit will be 60 minutes long so we can update your x-rays and scans.`

const resolveConfirmationSubtitle = (state) => {
  return state.isRenewalAppointment
    ? RENEWAL_APPOINTMENT_SUBTITLE
    : SCHEDULED_APPOINTMENT_SUBTITLE
}

const ScheduledPage = () => {
  const { state } = useLocation()

  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  const subtitle = resolveConfirmationSubtitle(state)

  return (
    <ConfirmationPage
      title="All set!"
      subtitle={subtitle}
    />
  )
}

export default ScheduledPage
