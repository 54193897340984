import { createClient } from '@wallyhealth/web-core'
import config from '../../config'

const client = createClient({ baseUrl: config.PATIENT_KAPI_URL })

export const fetchOrganizationInfo = ({ slug }) => {
  return client.get(`/organizations/info?slug=${slug}`)
    .then(response => response.data)
}

export const onboardGroup = ({ locationId, geoId, organizationId, holder, members }) => {
  const body = {
    locationId,
    geoId,
    holder,
    members
  }
  return client.post(`/groups/onboard?organizationId=${organizationId}`, body)
    .then(response => response.data)
}

export const onboardMembers = ({ groupId, members, enrollmentToken, geoId, locationId }) => {
  const body = {
    members,
    enrollmentToken,
    geoId,
    locationId
  }
  return client.post(`/groups/${groupId}/members`, body)
    .then(response => response.data)
}
