import React, { useState } from 'react'
import classes from 'classnames'
import { Accordion } from '@wallyhealth/web-core'
import plans from './plans'
import styles from './index.module.css'

const PlanLogo = ({ src }) => (
  <img
    className={styles.logo}
    src={src}
    alt="logo"
  />
)

const Header = ({ primaryPlanLogo, altPlans, altPlanId, onAltPlanSelected }) => (
  <div className={styles.header}>
    <div className={styles.header1}>
    </div>
    <div className={styles.header2}>
      <PlanLogo
        src={primaryPlanLogo}
      />
    </div>
    <div className={styles.header3}>
      <span className={styles.alt_provider_name}>
        Delta Dental
      </span>
      <div className={styles.alt_plan_names}>
        <span
          onClick={() => onAltPlanSelected(altPlans[0].id)}
          className={
            classes({
              [styles.alt_plan_name]: true,
              [styles.alt_plan_name_selected]: altPlanId === altPlans[0].id
            })
          }>
          {altPlans[0].name}
        </span>
        <span
          onClick={() => onAltPlanSelected(altPlans[1].id)}
          className={
            classes({
              [styles.alt_plan_name]: true,
              [styles.alt_plan_name_selected]: altPlanId === altPlans[1].id
            })
          }>
          {altPlans[1].name}
        </span>
      </div>
    </div>
  </div>
)

const TableRow = ({ label, content1, content2, className, className1, className2 }) => (
  <tr className={classes(styles.table, styles.table_row, className)}>
    <td className={styles.table_row_label}>
      {label}
    </td>
    <td className={classes(styles.table_cell, styles.table_cell1, className1)}>
      <span className={styles.table_cell_content}>
        {content1}
      </span>
    </td>
    <td className={classes(styles.table_cell, styles.table_cell2, className2)}>
      <span className={styles.table_cell_content}>
        {content2}
      </span>
    </td>
  </tr>
)

const OverviewTable = ({ plan1, plan2 }) => (
  <table className={classes(styles.table, styles.overview_table)}>
    <TableRow
      label="You pay"
      content1={`${plan1.overview.annual}/year`}
      content2={`${plan2.overview.annual}/year`}
    />
    <TableRow
      label="Deductible"
      content1={plan1.overview.deductible}
      content2={plan2.overview.deductible}
    />
    <TableRow
      label="Maximum"
      content1={plan1.overview.maximum}
      content2={plan2.overview.maximum}
    />
    <TableRow
      label="Cleanings"
      content1={plan1.overview.cleanings}
      content2={plan2.overview.cleanings}
    />
  </table>
)

const DiagnosticsTable = ({ plan1, plan2 }) => (
  <table className={classes(styles.table, styles.diagnostics_table)}>
    <TableRow
      label="X-rays"
      content1={plan1.diagnostics.rays}
      content2={plan2.diagnostics.rays}
    />
    <TableRow
      label="3D scans"
      content1={plan1.diagnostics.scans}
      content2={plan2.diagnostics.scans}
    />
    <TableRow
      label="Early cavity detection"
      content1={plan1.diagnostics.earlyCavityDetection}
      content2={plan2.diagnostics.earlyCavityDetection}
    />
  </table>
)

const RestorationTable = ({ plan1, plan2 }) => (
  <table className={classes(styles.table, styles.restoration_table)}>
    <TableRow
      label="Early stage"
      content1={plan1.restorativeServices.earlyStageCavity}
      content2={plan2.restorativeServices.earlyStageCavity}
    />
    <TableRow
      label="Late stage"
      content1={plan1.restorativeServices.lateStageCavity}
      content2={plan2.restorativeServices.lateStageCavity}
    />
  </table>
)

const MajorServiceTable = ({ plan1, plan2 }) => (
  <table className={classes(styles.table, styles.major_services_table)}>
    <TableRow
      label="Perio therapy"
      content1={plan1.majorServices.perioTherapy}
      content2={plan2.majorServices.perioTherapy}
    />
    <TableRow
      label="Perio maintenance"
      content1={plan1.majorServices.perioMaintenance}
      content2={plan2.majorServices.perioMaintenance}
    />
    <TableRow
      label="Referrals"
      content1={plan1.majorServices.referralService}
      content2={plan2.majorServices.referralService}
    />
    <TableRow
      label="Ortho"
      content1={plan1.majorServices.orthoService}
      content2={plan2.majorServices.orthoService}
    />
  </table>
)

const ExtrasTable = ({ plan1, plan2 }) => (
  <table className={classes(styles.table, styles.extras_table)}>
    <TableRow
      label="Whitening"
      content1={plan1.extras.whitening}
      content2={plan2.extras.whitening}
    />
    <TableRow
      label="Nightguard"
      content1={plan1.extras.nightguard}
      content2={plan2.extras.nightguard}
    />
  </table>
)

const Background = () => (
  <div className={styles.background}>
    <div className={styles.background1}>
    </div>
    <div className={styles.background2}>
    </div>
    <div className={styles.background3}>
    </div>
  </div>
)

const AccordionId = {
  OVERVIEW: 0,
  DIAGNOSTICS: 1,
  PREVENTION: 2,
  RESTORATION: 3,
  MAJOR_SERVICES: 4,
  EXTRAS: 5
}

const wallyPlanId = 0

const findPlan = (id) => {
  return plans.find(it => it.id === id)
}

const PlanComparisonPage = () => {
  const [expandedAccordionId, setExpandedAccordionId] = useState(AccordionId.OVERVIEW)
  const [altPlanId, setAltPlanId] = useState(plans[1].id)
  const altPlan = findPlan(altPlanId)

  const areDetailsEnabled = false

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <Header
          primaryPlanLogo={plans[wallyPlanId].logo}
          altPlanName={plans[1].name}
          onAltPlanSelected={setAltPlanId}
          altPlanId={altPlanId}
          altPlans={[plans[1], plans[2]]}
        />

        <Accordion
          id="overview-accordion"
          className={styles.accordion}
          iconClassName={styles.temp_hide_icon}
          titleClassName={styles.temp_accordion_title}
          titleClassNameORIG={styles.accordion_title}
          title="Overview"
          isExpanded={true}
          isExpandedORIG={expandedAccordionId === AccordionId.OVERVIEW}
          onToggle={(({ isExpanded }) => setExpandedAccordionId(isExpanded ? AccordionId.OVERVIEW : AccordionId.DIAGNOSTICS))}>
          <OverviewTable
            plan1={plans[wallyPlanId]}
            plan2={altPlan}
          />
        </Accordion>

        {areDetailsEnabled &&
          <>
            <Accordion
              id="diagnostics-accordion"
              className={styles.accordion}
              titleClassName={styles.accordion_title}
              title="Diagnostics"
              isExpanded={expandedAccordionId === AccordionId.DIAGNOSTICS}
              onToggle={(({ isExpanded }) => setExpandedAccordionId(isExpanded ? AccordionId.DIAGNOSTICS : AccordionId.PREVENTION))}>
              <DiagnosticsTable
                plan1={plans[wallyPlanId]}
                plan2={altPlan}
              />
            </Accordion>

            <Accordion
              id="restoration-accordion"
              className={styles.accordion}
              titleClassName={styles.accordion_title}
              title="Cavities"
              isExpanded={expandedAccordionId === AccordionId.RESTORATION}
              onToggle={(({ isExpanded }) => setExpandedAccordionId(isExpanded ? AccordionId.RESTORATION : AccordionId.MAJOR_SERVICES))}>
              <RestorationTable
                plan1={plans[wallyPlanId]}
                plan2={altPlan}
              />
            </Accordion>

            <Accordion
              id="major-accordion"
              className={styles.accordion}
              titleClassName={styles.accordion_title}
              title="Major services"
              isExpanded={expandedAccordionId === AccordionId.MAJOR_SERVICES}
              onToggle={(({ isExpanded }) => setExpandedAccordionId(isExpanded ? AccordionId.MAJOR_SERVICES : AccordionId.EXTRAS))}>
              <MajorServiceTable
                plan1={plans[wallyPlanId]}
                plan2={altPlan}
              />
            </Accordion>

            <Accordion
              id="major-accordion"
              className={styles.accordion}
              titleClassName={styles.accordion_title}
              title="Extras"
              isExpanded={expandedAccordionId === AccordionId.EXTRAS}
              onToggle={(({ isExpanded }) => setExpandedAccordionId(isExpanded ? AccordionId.EXTRAS : AccordionId.OVERVIEW))}>
              <ExtrasTable
                plan1={plans[wallyPlanId]}
                plan2={altPlan}
              />
            </Accordion>
          </>
        }

        <Background />

      </div>
    </div>
  )
}

export default PlanComparisonPage
