import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import config from '../../config'
import JoinStartPage from './JoinStartPage'
import JoinStudioPage from './JoinStudioPage'
import JoinPlanPage from './JoinPlanPage'
import JoinPaymentPage from './JoinPaymentPage'
import JoinPaymentPageV2 from './JoinPaymentPageV2'
import JoinWaitlistPage from './JoinWaitlistPage'
import { usePatient, useBilling } from './hooks'
import { PlanType } from './shared'
import ChatWidget from 'components/ChatWidget'

const StepId = {
  START: 1,
  STUDIO: 2,
  WAITLIST: 3,
  PLAN: 4,
  PAYMENT: 5,
  PAYMENT_V2: 6
}

const COPY_CONFIG = {
  regular: {
    banner: null,
    cta: 'Get started'
  },
  disc50: {
    banner: 'Get Wally for $199 (usually $249) until 10/31',
    cta: 'Get offer'
  },
  disc75: {
    banner: 'Get Wally for $174 (usually $249) until 10/31',
    cta: 'Get offer'
  }
}

const resolveCopy = (selector) => {
  return COPY_CONFIG[selector] || COPY_CONFIG.regular
}

const JoinFlow = () => {
  const locations = config.LOCATIONS

  const [search] = useSearchParams()

  const [stepId, setStepId] = useState(StepId.START)
  const [customer, setCustomer] = useState(null)
  const [planType, setPlanType] = useState(null)
  const [totalCostInCents, setTotalCostInCents] = useState(0)
  const [locationId, setLocationId] = useState(null)
  const [couponCode, setCouponCode] = useState(search.get('code'))

  const copySelector = search.get('copy')

  const { pricing, reloadPricing, isPricingLoading, prepSubscription } = useBilling({ couponCode })
  const { isOnboarding, onboardPatient } = usePatient()

  const scrollToTop = () => (
    window.scrollTo({ top: 0, behavior: 'smooth' })
  )
  useEffect(() => {
    scrollToTop()
  }, [stepId])

  const applyCouponCode = ({ code }) => {
    setCouponCode(code)
    if (code === couponCode) {
      reloadPricing()
    }
  }

  const handleCustomerInfo = (info) => {
    setCustomer(info)
    setStepId(StepId.STUDIO)
  }

  const onLocationPicked = (locationId) => {
    setLocationId(locationId)
    setStepId(StepId.PLAN)
  }

  const onPlanSelected = async ({ planType, totalCostInCents }) => {
    setPlanType(planType)
    setTotalCostInCents(totalCostInCents)
    setStepId(StepId.PAYMENT_V2)
  }

  const resolveCouponCode = () => {
    return (planType === PlanType.ANNUAL)
      ? couponCode
      : null
  }

  const doPrepSubscription = () => {
    return prepSubscription({
      billing: {
        planType,
        couponCode: resolveCouponCode()
      },
      patient: {
        email: customer.email,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.phone
      },
      location: locations.find(l => l.id === locationId)
    })
  }

  const onTokenGenerated = async ({ token }) => {
    await onboardPatient({
      billing: {
        token,
        planType,
        couponCode: resolveCouponCode()
      },
      patient: {
        email: customer.email,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phone: customer.phone
      },
      location: locations.find(l => l.id === locationId)
    })
  }

  return (
    <div>

      {stepId === StepId.START &&
        <JoinStartPage
          onInfoCaptured={handleCustomerInfo}
          couponCode={couponCode}
          copy={resolveCopy(copySelector)}
        />
      }

      {stepId === StepId.STUDIO &&
        <JoinStudioPage
          onLocationPicked={onLocationPicked}
          onLocationNotPicked={() => setStepId(StepId.WAITLIST)}
          locations={locations}
        />
      }

      {stepId === StepId.WAITLIST &&
        <JoinWaitlistPage
          goBack={() => setStepId(StepId.STUDIO)}
        />
      }

      {stepId === StepId.PLAN &&
        <JoinPlanPage
          onPlanSelected={onPlanSelected}
          pricing={pricing}
          isPricingLoading={isPricingLoading}
          reloadPricing={reloadPricing}
          applyCouponCode={applyCouponCode}
        />
      }

      {stepId === StepId.PAYMENT &&
        <JoinPaymentPage
          customer={customer}
          onTokenGenerated={onTokenGenerated}
          isProcessing={isOnboarding}
          totalCostInCents={totalCostInCents}
        />
      }

      {stepId === StepId.PAYMENT_V2 &&
        <JoinPaymentPageV2
          customer={customer}
          totalCostInCents={totalCostInCents}
          planType={planType}
          prepSubscription={doPrepSubscription}
        />
      }

      <ChatWidget />
    </div>
  )
}

export default JoinFlow
