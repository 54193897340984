import React, { useEffect, useState } from 'react'
import { TextHeader, TextInput, Logo, Button, Terms, Divider, DividerOrientation, useNotifications } from '@wallyhealth/web-core'
import { times } from 'ramda'
import trim from 'validator/lib/trim'
import { isValidPhoneNumber } from '../../../validators/phoneValidator'
import { isValidEmail } from '../../../validators/emailValidator'
import styles from './index.module.css'
import intelService from 'services/intelService'

const PAGE_NAME = 'ActivationStartPage'

const FEATURES = [
  'Free cleanings',
  'Free checkups',
  'Free whitening',
  '3D scans & X-rays',
  'Cavity reversal',
  'Clear aligners'
]

const SmartMouthStrip = () => (
  <div className={styles.smart_mouth}>
    <ul className={styles.smart_mouth_list}>
      {times((index) => (
        <li key={`smart-mouth-list-item-${index}`}>
          Be a smart mouth
        </li>
      ), 20)}
    </ul>
  </div>
)

const MainSection = ({ onInfoCaptured, isActivating }) => {
  const notifications = useNotifications()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const sanitize = (value) => {
    return trim(value)
  }

  const isButtonEnabled = () => {
    return sanitize(firstName) && sanitize(lastName) && sanitize(email) && sanitize(phone)
  }

  const recordSubmitClick = async (sanitizedInputs) => {
    await intelService.recordClick({
      clickName: 'HolderInfoSubmitted',
      viewName: PAGE_NAME,
      identity: sanitizedInputs
    })
  }

  const handleSubmit = () => {
    const sanitizedInputs = {
      firstName: sanitize(firstName),
      lastName: sanitize(lastName),
      email: sanitize(email),
      phone: sanitize(phone)
    }

    if (!isValidEmail(sanitizedInputs.email)) {
      notifications.error('Please enter a valid email.')
      return
    }

    if (!isValidPhoneNumber(sanitizedInputs.phone)) {
      notifications.error('Please enter a valid US phone number.')
      return
    }

    recordSubmitClick(sanitizedInputs)

    onInfoCaptured(sanitizedInputs)
  }

  return (
    <div className={styles.main}>
      <div className={styles.main_body}>
        <TextHeader
          className={styles.header}
          titleClassName={styles.title}
          title="Activate your membership."
        />

        <div className={styles.form}>
          <div className={styles.names}>
            <TextInput
              className={styles.name}
              label="First Name"
              value={firstName}
              onValueChange={setFirstName}
            />

            <TextInput
              className={styles.name}
              label="Last Name"
              value={lastName}
              onValueChange={setLastName}
            />
          </div>

          <TextInput
            className={styles.email}
            label="Work Email"
            value={email}
            onValueChange={setEmail}
          />

          <TextInput
            label="Phone"
            className={styles.phone}
            onValueChange={setPhone}
            value={phone}
          />

          <Button
            className={styles.button}
            onClick={handleSubmit}
            disabled={!isButtonEnabled()}
            label="Activate for $0"
            isLoading={isActivating}
          />
        </div>

        <Terms
          className={styles.terms}
        />
      </div>
    </div>
  )
}

const SupportingSection = () => (
  <div className={styles.supporting}>
    <ul className={styles.features}>
      {FEATURES.map((feature, index) =>
        <li
          key={`plan-feature-${index}`}
          className={styles.feature}>
          {feature}
        </li>
      )}
    </ul>
  </div>
)

const ActivationStartPage = ({ onInfoCaptured, isActivating }) => {
  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  return (
    <div className={styles.container}>
      <Divider
        className={styles.divider_horizontal}
        orientation={DividerOrientation.HORIZONTAL}
      />
      <Logo
        className={styles.logo}
      />
      <MainSection
        onInfoCaptured={onInfoCaptured}
        isActivating={isActivating}
      />
      <Divider
        className={styles.divider_vertical}
        orientation={DividerOrientation.VERTICAL}
      />
      <SupportingSection/>
      <SmartMouthStrip />
    </div>
  )
}

export default ActivationStartPage
