import { useEffect, useState } from 'react'
import Confetti from 'react-confetti'

const drawCircle = (renderingContext) => {
  renderingContext.beginPath()
  renderingContext.arc(0, 0, 9, 0, 2 * Math.PI)
  renderingContext.fill()
}

const Celebration = ({ durationInMs = 2000, width = window.innerWidth, height = window.innerHeight }) => {
  const [isRaining, setIsRaining] = useState(true)

  const scheduleDrain = () => {
    setTimeout(
      () => setIsRaining(false),
      durationInMs
    )
  }
  useEffect(() => {
    scheduleDrain()
  }, [])

  return (
    <Confetti
      width={width}
      height={height}
      numberOfPieces={isRaining ? 200 : 0}
      colors={['#8AC8BC', '#B5F5AF', '#FBEDDD', '#FFD8AB', '#2C685C']}
      drawShape={drawCircle}
    />
  )
}

export default Celebration
