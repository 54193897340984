import { useState, useEffect } from 'react'
import { FullPage, TextHeader, Button, TextInput, ButtonSize, ButtonType } from '@wallyhealth/web-core'
import classes from 'classnames'
import { map, omit } from 'ramda'
import { centsToDollarsTrunc } from '../../../utils/billingUtils'
import removeSrc from './remove.svg'
import styles from './index.module.css'
import intelService from 'services/intelService'

const PAGE_NAME = 'ActivationFamilyPage'

const Description = ({ organizationName, discountedCostInCents, fullCostInCents }) => (
  <p className={styles.description}>
    Cover your family at the <span className={styles.org_name}>{organizationName}</span> rate of <span className={styles.discounted_price}>${centsToDollarsTrunc(discountedCostInCents)}/year</span> (reg. ${centsToDollarsTrunc(fullCostInCents)}/year).
  </p>
)

const Member = ({ tempId, firstName, lastName, onFirstNameChanged, onLastNameChanged, canRemove, onRemove }) => (
  <div className={styles.member}>
    <TextInput
      label="First name"
      className={styles.first_name}
      value={firstName}
      onValueChange={(it) => onFirstNameChanged({ tempId, name: it })}
    />
    <TextInput
      label="Last name"
      className={styles.last_name}
      value={lastName}
      onValueChange={(it) => onLastNameChanged({ tempId, name: it })}
    />
    <img
      src={removeSrc}
      className={classes({
        [styles.remove]: true,
        [styles.remove_disabled]: !canRemove
      })}
      onClick={canRemove ? () => onRemove(tempId) : null}
      alt="x"
    />
  </div>
)

const AddButton = ({ onClick }) => (
  <span
    className={styles.add_button}
    onClick={onClick}>
    Add another
  </span>
)

const allMembersAreFullyDefined = (members) => members.every(
  it => it.firstName && it.lastName
)

const createEmptyMember = () => ({
  tempId: Date.now(),
  firstName: '',
  lastName: ''
})

const DEFAULT_MEMBERS = [
  createEmptyMember()
]

const ActivationFamilyPage = ({ onSkip, onInfoCaptured, organizationName, discountedCostInCents, fullCostInCents, isOnboarding }) => {
  const [members, setMembers] = useState(DEFAULT_MEMBERS)

  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  const seedNewMember = () => {
    setMembers([...members, createEmptyMember()])
  }

  const setFirstName = ({ tempId, name }) => {
    setMembers(
      members.map(it =>
        it.tempId === tempId ? { ...it, firstName: name } : it
      )
    )
  }

  const setLastName = ({ tempId, name }) => {
    setMembers(
      members.map(it =>
        it.tempId === tempId ? { ...it, lastName: name } : it
      )
    )
  }

  const canRemoveMember = () => {
    return (members.length) > 1
  }

  const removeMember = (tempId) => {
    setMembers(
      members.filter(it => it.tempId !== tempId)
    )
  }

  const canSubmit = () => {
    return allMembersAreFullyDefined(members)
  }

  const sanitizeMembers = () => {
    return map(omit(['tempId']), members)
  }

  const recordSubmitClick = async () => {
    await intelService.recordClick({
      clickName: 'MembersSubmit',
      viewName: PAGE_NAME,
      properties: {
        memberCount: members.length
      }
    })
  }

  const recordSkipClick = async () => {
    await intelService.recordClick({
      clickName: 'MembersSkip',
      viewName: PAGE_NAME
    })
  }

  const handleSubmit = () => {
    recordSubmitClick()
    onInfoCaptured(sanitizeMembers())
  }

  const handleSkip = () => {
    recordSkipClick()
    onSkip()
  }

  return (
    <FullPage bodyClassName={styles.container}>
      <div className={styles.wrapper}>

        <TextHeader
          className={styles.header}
          titleClassName={styles.title}
          subtitleClassName={styles.subtitle}
          title="Add family members"
          subtitle={
            <Description
              organizationName={organizationName}
              discountedCostInCents={discountedCostInCents}
              fullCostInCents={fullCostInCents}
            />
          }
        />

        <div className={styles.fam}>
          {members.map(it =>
            <Member
              key={it.tempId}
              tempId={it.tempId}
              firstName={it.firstName}
              lastName={it.lastName}
              onFirstNameChanged={setFirstName}
              onLastNameChanged={setLastName}
              onRemove={removeMember}
              canRemove={canRemoveMember(it.tempId)}
            />
          )}
        </div>

        <AddButton
          onClick={seedNewMember}
        />

        <Button
          label="Wrap it up"
          className={styles.confirm}
          disabled={!canSubmit()}
          onClick={handleSubmit}
          isLoading={isOnboarding}
          size={ButtonSize.SMALL}
        />

        <Button
          label="Skip"
          className={styles.skip}
          onClick={handleSkip}
          type={ButtonType.TERNARY}
          size={ButtonSize.SMALL}
        />
      </div>
    </FullPage>
  )
}

export default ActivationFamilyPage
