import { useQuery, useMutation } from '@tanstack/react-query'
import { useNotifications } from '@wallyhealth/web-core'
import { fetchOrganizationInfo, onboardGroup, onboardMembers } from './api'

export const useOrganizationInfo = ({ slug }) => {
  const { data: orgInfo = null, isLoading: isLoadingOrgInfo } = useQuery({
    queryKey: ['fetchOrganizationInfo', slug],
    queryFn: async () => {
      try {
        return fetchOrganizationInfo({ slug })
      } catch (error) {}
    }
  })

  return {
    orgInfo,
    isLoadingOrgInfo
  }
}

export const useOnboardGroup = () => {
  const notifications = useNotifications()

  const onboardGroupMutation = useMutation({
    queryKey: ['onboardGroup'],
    mutationFn: onboardGroup,
    onError: (error) => {
      notifications.error(error.message || 'Something went wrong. Please try again.')
    }
  })

  const onboardMembersMutation = useMutation({
    queryKey: ['onboardMembers'],
    mutationFn: onboardMembers,
    onError: (error) => {
      notifications.error(error.message || 'Something went wrong. Please try again.')
    }
  })

  return {
    onboardGroupRunner: {
      run: (options) => onboardGroupMutation.mutateAsync(options),
      isRunning: onboardGroupMutation.isPending,
      data: onboardGroupMutation.data
    },
    onboardMembersRunner: {
      run: (options) => onboardMembersMutation.mutateAsync(options),
      isRunning: onboardMembersMutation.isPending
    }
  }
}
