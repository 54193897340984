import wallyLogo from './wally.svg'

const plans = [
  {
    id: 1,
    provider: 'Wally',
    name: 'Membership',
    logo: wallyLogo,
    overview: {
      annual: '$0',
      deductible: '$0',
      maximum: 'Unlimited',
      cleanings: 'Unlimited'
    },
    diagnostics: {
      rays: 'Unlimited',
      scans: 'Unlimited',
      earlyCavityDetection: 'Unlimited'
    },
    restorativeServices: {
      earlyStageCavity: '$150/reversal',
      lateStageCavity: 'Referral to specialist'
    },
    majorServices: {
      perioTherapy: '$40/tooth',
      perioMaintenance: 'Unlimited',
      referralService: 'Trusted network with preferred pricing',
      orthoService: 'Clear aligners, $1800 on average'
    },
    extras: {
      whitening: 'Unlimited',
      nightguard: '$349 each'
    }
  },
  {
    id: 2,
    provider: 'Delta Dental',
    name: 'Base',
    logo: null,
    overview: {
      annual: '$180',
      deductible: '$50',
      maximum: '$1,000',
      cleanings: 2
    },
    diagnostics: {
      rays: 1,
      scans: 'Not covered',
      earlyCavityDetection: 'Not covered'
    },
    restorativeServices: {
      earlyStageCavity: 'N/A, drill & fill',
      lateStageCavity: '$250 drill & fill'
    },
    majorServices: {
      perioTherapy: '50% covered, $900 out of pocket',
      perioMaintenance: '$100/visit',
      referralService: '100s of treatments with opaque pricing',
      orthoService: 'Braces, not covered, $6500 out of pocket'
    },
    extras: {
      whitening: '$750/session',
      nightguard: '$699 each'
    }
  },
  {
    id: 3,
    provider: 'Delta Dental',
    name: 'Premier',
    logo: null,
    overview: {
      annual: '$475',
      deductible: '$50',
      maximum: '$2,500',
      cleanings: 2
    },
    diagnostics: {
      rays: 1,
      scans: 'Not covered',
      earlyCavityDetection: 'Not covered'
    },
    restorativeServices: {
      earlyStageCavity: 'N/A, drill & fill',
      lateStageCavity: '$250 drill & fill'
    },
    majorServices: {
      perioTherapy: '50% covered, $900 out of pocket',
      perioMaintenance: '$100/visit',
      referralService: '100s of treatments with opaque pricing',
      orthoService: 'Braces, $1500 covered, $5000 out of pocket'
    },
    extras: {
      whitening: '$750/session',
      nightguard: '$699 each'
    }
  }
]

export default plans
