import React, { useState } from 'react'
import { Button, useNotifications } from '@wallyhealth/web-core'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useNavigate } from 'react-router-dom'
import { centsToDollarsTrunc } from '../../../../utils/billingUtils'
import styles from './index.module.css'

const resolveStripeFontSize = () => {
  return (window.innerWidth > 768) ? 20 : 16
}

const createStripeOptions = (options = {}) => {
  const fontSize = resolveStripeFontSize()

  return {
    placeholder: options.placeholder,
    style: {
      base: {
        fontSize: `${fontSize}px`,
        fontFamily: 'Varta',
        color: '#106A5C',
        '::placeholder': {
          color: '#106A5C'
        },
        fontWeight: '600'
      },
      invalid: {
        color: '#C23D4B'
      }
    }
  }
}

const userFriendlyErrorTypes = ['card_error', 'validation_error']

const CardPayOption = ({ buttonClassName, totalCostInCents, recordPaymentClick, prepSubscription }) => {
  const notifications = useNotifications()
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()

  const [isRunningPayment, setIsRunningPayment] = useState(false)
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false)
  const [isCardExpiryComplete, setIsCardExpiryComplete] = useState(false)
  const [isCardCvcComplete, setIsCardCvcComplete] = useState(false)

  const onCardNumberChanged = ({ complete }) => {
    setIsCardNumberComplete(complete)
  }

  const onCardExpiryChanged = ({ complete }) => {
    setIsCardExpiryComplete(complete)
  }

  const onCardCvcChanged = ({ complete }) => {
    setIsCardCvcComplete(complete)
  }

  const areInputsValid = () => {
    return isCardNumberComplete && isCardExpiryComplete && isCardCvcComplete
  }

  const runPayment = async () => {
    setIsRunningPayment(true)

    const { clientSecret } = await prepSubscription()

    if (clientSecret) {
      confirmCardPayment({ clientSecret })
    }
  }

  const confirmCardPayment = async ({ clientSecret }) => {
    const data = {
      payment_method: {
        card: elements.getElement(CardNumberElement)
      }
    }

    const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, data)

    tryHandleError(error)
    tryHandleSuccessfulPayment(paymentIntent)

    setIsRunningPayment(false)
  }

  const tryHandleSuccessfulPayment = (paymentIntent) => {
    if (paymentIntent && paymentIntent.status === 'succeeded') {
      navigate('/welcome')
    }
  }

  const tryHandleError = (error) => {
    if (error) {
      handleError(error)
    }
  }

  const handleError = (error) => {
    const message = userFriendlyErrorTypes.includes(error.type)
      ? error.message
      : 'Oops, something went wrong. Please try again.'

    notifications.error(message)
  }

  const handleSubmit = async () => {
    if (!isRunningPayment) {
      recordPaymentClick({ clickName: 'PayClick' })
      runPayment()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.card_element}>
          <CardNumberElement
            onChange={onCardNumberChanged}
            options={createStripeOptions({ placeholder: 'Card number' })}
          />
        </div>

        <div className={styles.card_columns}>
          <div className={styles.card_expiry}>
            <CardExpiryElement
              options={createStripeOptions()}
              onChange={onCardExpiryChanged}
            />
          </div>

          <div className={styles.card_cvc}>
            <CardCvcElement
              options={createStripeOptions()}
              onChange={onCardCvcChanged}
            />
          </div>
        </div>
      </div>

      <div className={styles.button}>
        <Button
          className={buttonClassName}
          label={`Pay $${centsToDollarsTrunc(totalCostInCents)} by card`}
          disabled={!areInputsValid()}
          onClick={handleSubmit}
          isLoading={isRunningPayment}
        />
      </div>

    </div>
  )
}

export default CardPayOption
