import React, { useEffect } from 'react'
import { ConfirmationPage } from '@wallyhealth/web-core'
import intelService from '../../services/intelService'
import useQuery from 'hooks/useQuery'

export const CopyVariation = {
  DEFAULT: 0,
  FAMILY: 1
}

const SUBTITLE = {
  [CopyVariation.DEFAULT]: 'Your care team will text you soon and help you prepare for your first visit. We look forward to seeing you.',
  [CopyVariation.FAMILY]: 'Our care team will reach out to you soon and help you and your family prepare for your first visit. We look forward to seeing you!'
}

const WelcomePage = () => {
  const query = useQuery()
  const copyVariation = query.get('var') || CopyVariation.DEFAULT

  const recordView = () => {
    intelService.recordView({ viewName: 'WelcomePage' })
  }

  const onMount = () => {
    recordView()
  }

  useEffect(() => {
    onMount()
  }, [])

  return (
    <ConfirmationPage
      title="Welcome to Wally!"
      subtitle={SUBTITLE[copyVariation]}
    />
  )
}

export default WelcomePage
