import React, { useState, useEffect } from 'react'
import { FullPage, Select, TextHeader, useNotifications, Loader } from '@wallyhealth/web-core'
import { useNavigate } from 'react-router-dom'
import classes from 'classnames'
import { useAppointments } from '../hooks'
import intelService from '../../../services/intelService'
import config from '../../../config'
import styles from './index.module.css'
import SlotsWrapper from './SlotsWrapper'
import BookingAction from './BookingAction'

const PAGE_NAME = 'SchedulePage'

const toSelectLocation = (it) => ({
  id: it.id,
  label: it.name
})

const findLocationsByGeo = (geoId) => {
  return config.LOCATIONS
    .filter(it => it.geoId === geoId)
    .map(toSelectLocation)
}

const StudioName = ({ location }) => {
  if (!location) {
    return null
  }

  return (
      <span className={styles.location}>
        {location.name} Studio
      </span>
  )
}

const ScheduleSlotsPage = ({ isFetchingSlots, isFirstLoad, link, location, slots, onTryAgain, selectedLocationId, onLocationSelected, encodedConfirmationId }) => {
  const [isBooking, setIsBooking] = useState(false)
  const [selectedSlot, setSelectedSlot] = useState({})
  const [isOnStandby, setIsOnStandby] = useState(false)
  const notifications = useNotifications()
  const navigate = useNavigate()

  const canChangeLocation = link && link.geoId

  const {
    hasBookingFailed,
    bookAppointment
  } = useAppointments()

  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  const onSlotSelected = ({ slot, isOnStandby }) => {
    setSelectedSlot(slot)
    setIsOnStandby(isOnStandby)
    setIsBooking(true)
  }

  const onBookingFailed = () => {
    if (hasBookingFailed) {
      setIsBooking(false)
      setSelectedSlot({})
      notifications.error('Oops, something went wrong. Please try again.')
    }
  }
  useEffect(() => {
    onBookingFailed()
  }, [hasBookingFailed])

  const bookSlot = async () => {
    try {
      intelService.recordClick({
        clickName: 'BookSlot',
        viewName: PAGE_NAME
      })

      await bookAppointment({
        linkId: link.id.value,
        startTimeUtc: selectedSlot.startDateTimeUtc,
        startTimeLocal: selectedSlot.startDateTimeLocal,
        durationInMinutes: selectedSlot.durationInMinutes,
        appointmentType: link.appointmentType,
        providerId: selectedSlot.providerId,
        podId: selectedSlot.podId,
        locationId: selectedSlot.locationId,
        isOnStandby,
        encodedConfirmationId
      })
      navigate('/schedule/done', {
        state: {
          isRenewalAppointment: link.appointmentType === 'RENEWAL'
        }
      })
    } catch { }
  }

  const onBookingCanceled = () => {
    setIsBooking(false)
    setSelectedSlot({})

    intelService.recordClick({
      clickName: 'CancelBooking',
      viewName: PAGE_NAME
    })
  }

  return (
    <FullPage bodyClassName={styles.container}>

      {canChangeLocation &&
        <Select
          value={selectedLocationId}
          items={findLocationsByGeo(link.geoId)}
          onSelected={(locationId) => onLocationSelected({ locationId })}
          className={styles.locations}
        />
      }

      {!canChangeLocation &&
        <StudioName
          location={location}
        />
      }

      {!isFirstLoad &&
        <TextHeader
          title="Let's find a time that works for you."
          titleClassName={styles.title}
          className={styles.header}
        />
      }

      {!isFetchingSlots &&
        <SlotsWrapper
          className={styles.slots}
          slots={slots}
          onSlotSelected={onSlotSelected}
          selectedTimeSlot={selectedSlot}
          onTryAgain={onTryAgain}
          isFirstLoad={isFirstLoad}
        />
      }

      {isFetchingSlots &&
        <Loader
          isFadeInEnabled={true}
          className={classes({
            [styles.loader]: true,
            [styles.loader_init]: isFirstLoad
          })}
        />
      }

      {isBooking &&
        <BookingAction
          onCanceled={onBookingCanceled}
          onTriggered={bookSlot}
        />
      }

    </FullPage>
  )
}

export default ScheduleSlotsPage
