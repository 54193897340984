import { useEffect } from 'react'
import { Button, ButtonSize, ButtonType, FullPage, TextHeader } from '@wallyhealth/web-core'
import styles from './index.module.css'
import intelService from 'services/intelService'
import Celebration from 'components/Celebration'

const PAGE_NAME = 'ActivationConfirmationPage'

const ActivationConfirmationPage = ({ onSkipMembers, onAddMembers }) => {
  useEffect(() => {
    intelService.recordView({ viewName: PAGE_NAME })
  }, [])

  const recordClick = async (name) => {
    await intelService.recordClick({
      clickName: name,
      viewName: PAGE_NAME
    })
  }

  const handleAddMembers = () => {
    recordClick('AddMembers')
    onAddMembers()
  }

  const handleOnSkip = () => {
    recordClick('SkipMembers')
    onSkipMembers()
  }

  return (
    <FullPage>
      <div className={styles.container}>
        <Celebration />
        <TextHeader
          className={styles.header}
          titleClassName={styles.title}
          subtitleClassName={styles.subtitle}
          title='Your membership is now active.'
          subtitle='Add your family members and enjoy the same perks together.'
        />
        <Button
          className={styles.cta}
          label='Add family members'
          type={ButtonType.DEFAULT}
          onClick={handleAddMembers}
          size={ButtonSize.SMALL}
        />
        <Button
          className={styles.skip}
          label='Skip'
          type={ButtonType.TERNARY}
          onClick={handleOnSkip}
          size={ButtonSize.SMALL}
        />
      </div>
    </FullPage>
  )
}

export default ActivationConfirmationPage
